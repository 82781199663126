<template>
  <section class="block-video" :class="[background]">
    <VideoPlayerDefault class="z-40" :media="block.media" />
  </section>
</template>

<script>
import { background } from '@/mixins';
import VideoPlayerDefault from '@/components/Video/VideoPlayerDefault';

export default {
  name: 'BlockVideo',
  components: {
    VideoPlayerDefault
  },
  props: {
    block: [Array, Object]
  },
  mixins: [background]
};
</script>

<style lang="postcss" scoped>
.block-video {
  @apply py-32;
  @apply flex items-center justify-center;
  @screen lg {
    @apply min-h-screen;
  }
}
</style>
