<template>
  <main ref="loader" id="home" :class="[backgroundColour]">
    <div class="home__blocks" v-if="content">
      <div v-for="(block, i) in content" :key="block.id">
        <BlockMultiWave
          v-if="block.type === 'flash'"
          :block="block"
          @mask="setMask(i, $event)"
          :lastMask="getMask(i - 1)"
        >
          <BlockFlash :block="block" />
        </BlockMultiWave>

        <BlockMultiWave
          v-if="block.type === 'hero'"
          :block="block"
          @mask="setMask(i, $event)"
          :lastMask="getMask(i - 1)"
        >
          <BlockHero :block="block" />
        </BlockMultiWave>

        <BlockMultiWave
          v-if="block.type === 'text'"
          :block="block"
          @mask="setMask(i, $event)"
          :lastMask="getMask(i - 1)"
        >
          <BlockText :block="block" />
        </BlockMultiWave>

        <BlockMultiWave
          v-if="block.type === 'video'"
          :block="block"
          @mask="setMask(i, $event)"
          :lastMask="getMask(i - 1)"
        >
          <BlockVideo :block="block" />
        </BlockMultiWave>

        <BlockMultiWave
          v-if="block.type === 'caseStudy'"
          :block="block"
          @mask="setMask(i, $event)"
          :lastMask="getMask(i - 1)"
        >
          <BlockCase :block="block" />
        </BlockMultiWave>

        <BlockMultiWave
          v-if="block.type === 'split'"
          :block="block"
          @mask="setMask(i, $event)"
          :lastMask="getMask(i - 1)"
        >
          <BlockSplit :block="block" />
        </BlockMultiWave>

        <BlockMultiWave
          v-if="block.type === 'carousel'"
          :block="block"
          @mask="setMask(block.id, $event)"
          :lastMask="getMask(i - 1)"
        >
          <BlockCarousel :block="block" />
        </BlockMultiWave>
      </div>
    </div>
  </main>
</template>

<script>
import { loader, maskBus } from '@/mixins';
import { mapGetters } from 'vuex';

import {
  BlockHero,
  BlockText,
  BlockVideo,
  BlockCase,
  BlockSplit,
  BlockCarousel,
  BlockMultiWave,
  BlockFlash
} from '@/components/Block/';

export default {
  name: 'Home',
  components: {
    BlockHero,
    BlockText,
    BlockVideo,
    BlockCase,
    BlockSplit,
    BlockCarousel,
    BlockMultiWave,
    BlockFlash
  },
  title: 'Better lives are built on solid foundations',
  data: () => {
    return {
      homepage: null,
      content: null
    };
  },
  mixins: [loader, maskBus],
  computed: {
    ...mapGetters(['getIsMobileMenuOpen', 'getIsOpen']),
    backgroundColour() {
      return this.homepage
        ? `bg-brand-${this.homepage.options.backgroundColour}`
        : null;
    }
  },
  methods: {
    getContent() {
      this.$http
        .get('api/v1/pages/homepage.json')
        .then(response => {
          this.homepage = response.data;
          this.content = response.data.blocks;
        })
        .catch(error => console.error(error));
    }
  }
};
</script>
