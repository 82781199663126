<template>
  <div class="player">
    <video
      ref="video"
      controls
      controlsList="nodownload"
      :poster="media.images[0].src"
    >
      <source :src="media.video.source.url" :type="media.video.source.type" />
      <p>
        Your browser doesn't support HTML5 video. Here is a
        <a :href="media.video.source.url">link to the video</a>
        instead.
      </p>
    </video>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'VideoPlayerDefault',
  props: {
    media: [Array, Object]
  },
  computed: {
    ...mapGetters(['getIsMobile'])
  }
};
</script>

<style lang="postcss" scoped>
.player {
  @apply relative;
  width: 100%;
  @screen lg {
    width: 70vw;
  }
  video {
    width: 100%;
    @apply object-cover;
  }
}
</style>
